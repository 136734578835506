img[src$='#center'] {
  display: block;
  margin: 1.0rem auto;
  max-width: 100%;
  height: auto;
}

.summary-content {
  color: #555555;
  display: block;
}

.home-title{
  color: #e51843;
  font-size: 3rem;
}

.home-subtitle{
  font-size: 18px;
  font-family: "Satisfy", cursive;
}

.post-info-share{
  font-size: 32px;
}


